<template>
  <div>
    <div class="crumbs">
      <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
    </div>
    <div class="container">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="Union名称:">
          <el-input v-model="queryParams.name" placeholder="请输入Union名称" clearable></el-input>
        </el-form-item>
        <div style="float: right">
          <el-button type="primary" @click="changeQuery()">搜索</el-button>
        </div>
      </el-form>
      <div class="action">
        <el-button type="primary" @click="add()">新增</el-button>
      </div>
      <!-- 表单内容 -->
      <el-table :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
        <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
        <el-table-column label="创建时间" prop="created_at" width="180" align="center"></el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="180" align="center"></el-table-column>
        <el-table-column label="名称" prop="name" align="center"></el-table-column>
        <el-table-column label="后台域名" prop="bind_domain" align="center"></el-table-column>
        <el-table-column label="状态" align="center" width="120">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.state === 1">不可用</el-tag>
            <el-tag type="primary" v-if="scope.row.state === 2">可用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线</el-button>
            <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">上线
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize"
                  v-on:refreshPageNum="refreshPageNum"/>

      <!-- 新增 编辑 -->
      <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
        <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">

          <el-form-item label="Union名称" prop="name">
            <el-input class="lage" v-model="form.name" placeholder="请输入Union名称"></el-input>
          </el-form-item>
          <el-form-item label="后台域名" prop="name">
            <el-input class="lage" v-model="form.bind_domain" placeholder="请输入要绑定的后台域名"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-radio-group v-model="form.state">
              <el-radio :label="2">上线</el-radio>
              <el-radio :label="1">下线</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveData">确定</el-button>
            <el-button @click="addVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 上线、下线提示框 -->
      <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
        <div class="del-dialog-cnt">{{ confirmContent }}</div>
        <span slot="footer" class="dialog-footer">
                            <el-button @click="confirmVisible = false">取 消</el-button>
                            <el-button type="primary" @click="stateData">确 定</el-button>
                        </span>
      </el-dialog>

    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
//import { Loading } from 'element-ui';
import {request} from '@/common/request';
import {deal} from '@/common/main';

let loads = new loadEvents();
export default {
  // name: 'seller',
  components: {
    Breadcrumb,
    Pagination
  },
  data() {
    return {
      activeName: 'first',
      options: [{
        value: '2',
        label: '可用'
      }, {
        value: '0',
        label: '不可用'
      },],
      menuList: ['设置', '系统Union管理'],
      pageInfo: {},
      confirmContent: '',
      versionForm: {},
      addVisible: false,
      confirmVisible: false,
      value: '',
      form: {},
      queryParams: {
        page: 1,
        pagesize: 10
      },
      header: {},
      // 验证
      rules: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }],
        state: [{
          required: true,
          message: '状态不能为空',
          trigger: 'blur'
        }],

      },
      hearder: {},
      title: '加载中',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      platformList: [],
      positionList: [],
      queryUserParams: {
        name: '',
        page: 1,
        pagesize: 10,
      },
      userInfo: {},
    }

  },

  created() {
    this.hearder.token = localStorage.getItem('token');
    this.hearder.platform = 'admin';
    deal.getconf('', this)
    this.loadData()
  },
  methods: {
    // tab切换
    handleClick(tab) {
      if (tab.paneName === "first") {
        this.loadData()
      } else {
        this.loadUserData()

      }
    },
    //平台
    statestam(row) {
      return deal.platformFormat(row.platform);
    },

    // 版本列表
    loadData() {
      this.loading = true;
      let _this = this
      request.get('/system/union/list', this.queryParams).then(ret => {
        if (ret.code == 1) {
          this.loading = false;
          this.pageInfo = ret.data;
          this.pageInfo.list = ret.data.list;
          this.pageInfo.list.map(function (val) {
            val.ctime = deal.timestamp(val.ctime, 'unix');
            if (_this.platformList) {
              _this.platformList.map((item) => {
                if (item.key === val.platform) {
                  _this.$set(val, 'platform_name', item.name)
                }
              })
            }
            return val;
          })
        } else {
          this.$message.error(ret.msg);
        }
      });
    },


    //新增
    add() {
      this.mode = 'add';
      this.title = '新增Union';
      this.form = {
        state: 2,
        is_update: 1,
      };
      this.addVisible = true;
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    },
    // 分页
    changeQuery() {
      this.queryParams.page = 1;
      this.loadData();
    },
    refreshPageSize(val) {
      this.queryParams.pagesize = val;
      this.loadData();
    },
    refreshPageNum(val) {
      this.queryParams.page = val;
      this.loadData();
    },
    // 上传文件
    beforeAvatarUpload(file) {
      loads.open('.avatar-uploader .el-upload', '上传中')
      let fileName = file.name
      let pos = fileName.lastIndexOf('.')
      let lastName = fileName.substring(pos, fileName.length)
      console.log(lastName.toLowerCase());
      if (lastName.toLowerCase() !== '.wgt' && lastName.toLowerCase() !== '.ipa' && lastName.toLowerCase() !== '.apk') {
        this.$message.error('不支持此文件格式!');
      }
      // Loading.service({ text: '文件上传中' });

    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      // Loading.service().close();
      if (res.code == 1) {
        loads.close()
        this.$set(this.form, 'url', res.data.uri);
      }
    },
    // 编辑
    edit(index, row) {
      this.mode = 'edit';
      this.title = '编辑Union';
      this.current = row.id;
      const item = this.pageInfo.list[index];
      //处理时间
      this.form = Object.assign({}, item);
      this.$set(this.form, 'ctime', item.ctime);
      console.log(77777, this.form)
      this.addVisible = true;
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    },
    // 状态
    state(row, state) {
      if (state === 2) {
        this.confirmContent = '确定是否上线?';
      } else {
        this.confirmContent = '确定是否下线?(别下线，逻辑没做完)';
      }
      this.form = Object.assign({}, row);
      this.form.state = state;
      console.log(333, this.form)
      this.form.ctime = deal.timestamp(this.form.ctime);
      this.confirmVisible = true;
    },
    // 确定下线
    stateData() {
      request.post('/system/union/edit', this.form).then(ret => {
        if (ret.code == 1) {
          this.$message.success('操作成功');
          this.loadData();
          this.confirmVisible = false;
        } else {
          this.$message.error(ret.msg);
        }
      });
    },
    // 新增编辑
    saveData() {
      if (this.$refs.form) {
        this.$refs.form.validate(valid => {
          if (valid) {
            var url = this.mode === 'add' ? '/system/union/add' : '/system/union/edit';
            var param = Object.assign({}, this.form);
            param.ctime = deal.timestamp(this.form.ctime);
            //处理时间
            request.post(url, param).then(ret => {
              if (ret.code == 1) {
                this.addVisible = false;
                this.loadData();
                this.$message.success((this.mode === 'add' ? '新增' : '编辑') +
                    '成功');
                this.$refs.form.clearValidate()
              } else {
                this.$message.error(ret.msg);
              }
            });
          } else {
            this.$message.error('请补全信息');
            return false;
          }
        });
      }
      ;
    },
    /// 用户版本数据

    cellstyle() {
      return 'text-align: center';
    },
    //获取用户版本列表
    loadUserData() {
      this.loading = true;
      let _this = this
      request.get('/system/version/user/list', this.queryUserParams).then(ret => {
        if (ret.code == 1) {
          this.loading = false;
          this.userInfo = ret.data;
          this.userInfo.list = ret.data.list
          this.userInfo.list.map(function (val) {
            if (_this.platformList) {
              _this.platformList.map((item) => {
                if (item.key === val.platform) {
                  _this.$set(val, 'platform_name', item.name)
                }
              })
            }
            return val;
          })
        } else {
          this.$message.error(ret.msg);
        }
      });
    },
    // 分页
    changeUserQuery() {
      this.queryUserParams.page = 1;
      this.loadUserData();
    },
    refreshUserPageSize(val) {
      this.queryUserParams.pagesize = val;
      this.loadUserData();
    },
    refreshUserPageNum(val) {
      this.queryUserParams.page = val;
      this.loadUserData();
    }
  },


}
</script>
<style scoped>
.red {
  color: #ff0000;
}

.green {
  color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
  padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
  padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
  margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.dialog_box .avatar {
  font-size: 28px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.dialog_box .span_gray {
  font-size: 12px;
  color: gray;
}

.dialog_box .up {
  margin-top: 10px;
  margin-bottom: -10px;
}

.form-input >>> .el-input__inner {
  width: 900px;
  border-radius: 4px;
}

.tijiao {
  margin-top: 60px;
}
</style>